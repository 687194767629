<template>
  <div class="d-flex justify-content-end mb-1">
    <slot name="avisos-baixa-lote" />
    <button
      v-if="!possuiAgrupador"
      v-b-tooltip.hover
      style="font-size: 11.5px;padding: .40rem;"
      title="O download será feito conforme os filtros atualmente selecionados"
      class="btn btn-sm btn-outline-primary"
      @click="abrirModal()"
    >
      <feather-icon
        icon="DownloadIcon"
        size="18"
      />
      <span class="ml-50">Baixar {{ tipoArquivo }}</span>
    </button>
    <div
      v-else
    >
      <!-- style="height: 10px !important;" -->
      <v-select
        id="baixaEmLoteSelect"
        v-model="agrupador"
        v-b-tooltip.hover
        title="O download será feito conforme os filtros atualmente selecionados"
        :options="agrupadores"
        :clearable="true"
        label="nome"
        :placeholder="`Baixar ${tipoArquivo}`"
        style="font-size: 11.5px; width: 250px;"
        @input="abrirModal()"
      />
    </div>
    <b-modal
      id="modal-download"
      ref="modal-download"
      title="Exportar itens"
    >
      <template #modal-footer>
        <button
          class="btn btn-sm btn-danger"
          @click="fecharModal"
        >
          Cancelar
        </button>
        <button
          class="btn btn-sm btn-success"
          @click="baixarEmLote"
        >
          Baixar
        </button>
      </template>
      <b-form-group
        label="Nome do arquivo"
        label-for="nome-arquivo-input"
      >
        <b-form-input
          id="nome-arquivo-input"
          v-model="nomeArquivo"
          placeholder="Selecione o nome do arquivo"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<style lang="scss">
.col-valores{
  white-space: nowrap;
}

[dir] #baixaEmLoteSelect .vs__dropdown-toggle{
  border-color: var(--primary) !important;
}
</style>

<script>
import {
  getUserInfo,
} from '@/auth/utils'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    vSelect,
  },
  props: {
    tipoArquivo: {
      type: String,
      default: 'todas as certidões',
    },
    tipo: {
      type: String,
      default: '',
    },
    filtro: {
      type: Array,
      default: () => [],
    },
    palavrasFiltro: {
      type: Array,
      default: () => [],
    },
    possuiAgrupador: {
      type: Boolean,
      default: false,
    },
    agrupadores: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      nomeArquivo: this.nomePadrao,
      nomeEmpresa: getUserInfo().empresa,
      agrupador: null,
    }
  },
  computed: {
    nomePadrao: {
      get() {
        const data = new Date()
        return `${this.tipo}-${this.nomeEmpresa}${this.palavrasFiltroSelecionada}-${data.getDate()}-${data.getMonth() + 1}-${data.getFullYear()}`
      },
    },
    palavrasFiltroSelecionada: {
      get() {
        let resultado = ''
        for (let i = 0; i < this.filtro.length;) {
          const filtroAtual = this.filtro[i]
          for (let j = 0; j < this.palavrasFiltro.length;) {
            const index = this.palavrasFiltro[j].findIndex(x => filtroAtual.label && filtroAtual.label.includes(x))
            if (index > -1) {
              resultado += `-${this.palavrasFiltro[j][index]}`
            }
            j += 1
          }
          i += 1
        }
        return resultado
      },
    },
  },
  watch: {
    filtro: {
      deep: true,
      handler() {
        this.atualizarNome()
      },
    },
  },
  created() {
    this.nomeArquivo = this.nomePadrao
  },
  methods: {
    baixarEmLote() {
      if (this.possuiAgrupador) {
        this.$emit('baixar-lote', { nomeArquivo: this.nomeArquivo, agrupador: this.agrupador })
      } else {
        this.$emit('baixar-lote', this.nomeArquivo)
      }
      this.fecharModal()
    },
    fecharModal() {
      this.$refs['modal-download'].hide()
      this.agrupador = null
    },
    abrirModal() {
      this.$refs['modal-download'].show()
    },
    atualizarNome() {
      this.nomeArquivo = this.nomePadrao
    },
  },
}
</script>
